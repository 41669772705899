<template>
    <v-popover trigger='hover' offset="-2" placement="right" :delay='{ show: 50, hide: 50 }' popoverClass="action-block as-popover"> 
        <div class="as-trigger" @click.stop="">
            <svg-importer icon-name="icons/more" />
        </div>

        <div slot="popover" class="text-body bg-white border rounded select-none min-w-67 select-block shipments-actions">
            <div v-for="action in actions" :key="action.id">
                <div
                    v-if="showAction(action)"
                    class="parent flex px-5 py-3 relative flex flex__align-center flex__justify-between"
                    @click.stop="emitClick(action)"
                    :class="{ 'bg-gray-200 text-gray-500 cursor-not-allowed': !action.active, 'cursor-pointer hover:bg-gray-100': action.active, 'border__top-grey': action.bt, 'bc-grey': action.show }">

                    <p class="no-wrap">{{ action.label }}</p>

                    <fa v-if="action.children && action.children.length" :icon="['fal','chevron-right']" />

                    <div v-if="action.children && action.children.length" class="child absolute top-0 z-10 bg-white border rounded select-none flex flex__column" style="right: 100%;">
                        <div 
                            v-for="child in action.children"
                            :key="child.id"
                            class="parent flex px-5 py-3 relative flex flex__align-center flex__justify-between" 
                            :class="{ 'bg-gray-200 text-gray-500 cursor-not-allowed': !child.active, 'cursor-pointer hover:bg-gray-100': child.active, 'border__top-grey': child.bt, 'bc-grey': child.show }"
                            @click.stop="emitClick(child)"
                        >
                            <p class="mr-2 no-wrap">{{ child.label }}</p>

                            <fa v-if="child.children && child.children.length" :icon="['fal','chevron-right']" />

                            <div v-if="child.children && child.children.length" class="child absolute top-0 z-10 bg-white border rounded select-none flex flex__column" style="right: 100%;">
                                <div 
                                    v-for="subChild in child.children"
                                    :key="subChild.id"
                                    class="parent flex px-5 py-3 relative flex flex__align-center flex__justify-between" 
                                    :class="{ 'bg-gray-200 text-gray-500 cursor-not-allowed': !subChild.active, 'cursor-pointer hover:bg-gray-100': subChild.active, 'border__top-grey': subChild.bt, 'bc-grey': subChild.show }"
                                    @click.stop="emitClick(subChild)"
                                >
                                    <p class="no-wrap">{{ subChild.label }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="value.is_archived"
                class="parent flex px-5 py-3 relative flex flex__align-center flex__justify-between cursor-pointer hover:bg-gray-100"
                @click.stop="emitClick({ id: 'dearchive', label: 'Dearchive', active: true, single_only: true, bt: false })"
            >
                 <p class="no-wrap">Dearchive</p>
            </div>

            <div
                v-if="!value.is_archived"
                class="parent flex px-5 py-3 relative flex flex__align-center flex__justify-between cursor-pointer hover:bg-gray-100"
                @click.stop="emitClick({ id: 'archive', label: 'Archive', active: true, single_only: true, bt: false })"
            >
                 <p class="no-wrap">Archive</p>
            </div>

            <div
                class="parent flex px-5 py-3 relative flex flex__align-center flex__justify-between cursor-pointer hover:bg-gray-100 border__top-grey"
                @click.stop="emitClick({ id: 'snooze', label: 'Snooze and reminder', active: true, single_only: true, bt: false })"
            >
                 <p class="no-wrap">Snooze and reminder</p>
            </div>

            <div
                v-if="(value.status === 'PRE_TRANSPORT' || value.status === 'CREATED') && value.activeSnooze"
                class="parent flex px-5 py-3 relative flex flex__align-center flex__justify-between cursor-pointer hover:bg-gray-100"
                @click.stop="emitClick({ id: 'close-snooze', label: 'Close snooze', active: true, single_only: true, bt: false })"
            >
                 <p class="no-wrap">Close snooze</p>
            </div>

            <div
                v-if="value.activeReminder || value.expiredReminder"
                class="parent flex px-5 py-3 relative flex flex__align-center flex__justify-between cursor-pointer hover:bg-gray-100"
                @click.stop="emitClick({ id: 'close-reminder', label: 'Close reminder', active: true, single_only: true, bt: false })"
            >
                 <p class="no-wrap">Close reminder</p>
            </div>
        </div>
    </v-popover>
</template>

<script>
    import { VPopover } from 'v-tooltip';

    export default {

        name: 'InvoiceControlActions',

        props: {
            single: {
                required: false,
                default: true,
                type: Boolean,
            },
            tableRef: {
                required: false,
                default: '',
                type: String,
            },
            exception_details: {
                type: Array,
                default: () => { return [] },
            },
            value: {
                default: () => { return {} }
            }
        },

        data() {
            return {
                show: false,
                actions: [
                    {
                        id: 'add_event',
                        label: 'Add event',
                        active: true,
                        single_only: true
                    },
                    {
                        id: 'set_time',
                        label: 'Set time',
                        active: true,
                        single_only: true,
                        show: false,
                        children: [
                            {
                                id: 'set_pick_up_starts_at',
                                label: 'Set pickup starts at',
                                active: true,
                                single_only: true,
                            },
                            {
                                id: 'set_pick_up_ends_at',
                                label: 'Set pickup ends at',
                                active: true,
                                single_only: true,
                            },
                            {
                                id: 'set_actual_time_pick_up_at',
                                label: 'Set actual time pickup at',
                                active: true,
                                single_only: true,
                            },
                            {
                                id: 'set_delivery_starts_at',
                                label: 'Set delivery start at',
                                active: true,
                                single_only: true,
                            },
                            {
                                id: 'set_delivery_ends_at',
                                label: 'Set delivery ends at',
                                active: true,
                                single_only: true,
                            },
                            {
                                id: 'set_actual_time_delivery_at',
                                label: 'Set actual time delivery at',
                                active: true,
                                single_only: true,
                            },
                            {
                                id: 'set_actual_time_departure_at',
                                label: 'Set actual time departure at',
                                active: true,
                                single_only: true,
                            },
                            {
                                id: 'set_estimated_time_departure_at',
                                label: 'Set estimated time departure at',
                                active: true,
                                single_only: true,
                            },
                            {
                                id: 'set_actual_time_arrival_at',
                                label: 'Set actual time arrival at',
                                active: true,
                                single_only: true,
                            },
                            {
                                id: 'set_estimated_delivery_starts_at',
                                label: 'Set estimated time arrival starts at',
                                active: true,
                                single_only: true,
                            },
                            {
                                id: 'set_estimated_delivery_ends_at',
                                label: 'Set estimated time arrival ends at',
                                active: true,
                                single_only: true,
                            },
                        ]
                    },
                    {
                        id: 'edit_shipment',
                        label: 'Edit details',
                        active: true,
                        single_only: true
                    },
                    {
                        id: 'add_comment',
                        label: 'Add comment',
                        active: true,
                        single_only: true
                    },
                    {
                        id: 'generate_email',
                        label: 'Generate email',
                        active: true,
                        single_only: true
                    },
                    // {
                    //     id: 'change_status',
                    //     label: 'Change shipment status',
                    //     active: true,
                    //     single_only: true,
                    //     show: false,
                    //     bt: true,
                    //     children: [
                    //         {
                    //             id: 'no_status',
                    //             label: 'No status',
                    //             active: true,
                    //             single_only: true
                    //         },
                    //         {
                    //             id: 'pre_transport',
                    //             label: 'Pre transport',
                    //             active: true,
                    //             single_only: true
                    //         },
                    //     ]
                    // },
                    // {
                    //     id: 'exceptions',
                    //     label: 'Exceptions',
                    //     active: true,
                    //     single_only: true,
                    //     show: false,
                    //     bt: true,
                    //     children: [
                    //         {
                    //             id: 'resolve',
                    //             label: 'Resolve',
                    //             active: true,
                    //             single_only: true
                    //         },
                    //         // {
                    //         //     id: 'investigate',
                    //         //     label: 'Investigate',
                    //         //     active: true,
                    //         //     single_only: true,
                    //         // },
                    //         {
                    //             id: 'change_exception_at',
                    //             label: 'Change Exception at',
                    //             active: true,
                    //             single_only: true,
                    //             show: false,
                    //             bt: true,
                    //             children: [
                    //                 {
                    //                     id: 'pick_up',
                    //                     label: 'Pickup',
                    //                     active: true,
                    //                     single_only: true
                    //                 },
                    //                 {
                    //                     id: 'transit',
                    //                     label: 'Transit',
                    //                     active: true,
                    //                     single_only: true
                    //                 },
                    //                 {
                    //                     id: 'customs',
                    //                     label: 'Customs',
                    //                     active: true,
                    //                     single_only: true
                    //                 },
                    //                 {
                    //                     id: 'delivery',
                    //                     label: 'Delivery',
                    //                     active: true,
                    //                     single_only: true
                    //                 },
                    //             ]
                    //         },
                    //         // {
                    //         //     id: 'change_exception_detail',
                    //         //     label: 'Change Exception details',
                    //         //     active: true,
                    //         //     single_only: true,
                    //         //     bt: true,
                    //         //     children: this.exception_details,
                    //         // },
                    //         // {
                    //         //     id: 'send_information',
                    //         //     label: 'Set Send information to...',
                    //         //     active: true,
                    //         //     single_only: true,
                    //         //     bt: true,
                    //         // },
                    //     ]
                    // },
                    // {
                    //     id: 'file_claim',
                    //     label: 'File a Claim',
                    //     active: true,
                    //     single_only: true,
                    //     bt: true,
                    // },
                    // {
                    //     id: 'resolve_claim',
                    //     label: 'Resolve a Claim',
                    //     active: true,
                    //     single_only: true,
                    //     bt: true,
                    // },
                    // {
                    //     id: 'claim_log',
                    //     label: 'Claim history log',
                    //     active: true,
                    //     single_only: true,
                    //     bt: false,
                    // },
                    {
                        id: 'to_price_lead',
                        label: 'Link price lead time',
                        active: true,
                        single_only: true,
                        bt: false,
                    },
                ]
            }
        },

        computed: {
            containerHeight() {
                const itemHeight = 43;
                return (itemHeight * this.actions.length) + 50
            }
        },
        created() {

        },
        mounted() {
            if (this.tableRef) {
                const table = this.findRefByName(this.tableRef)
                table.setOverflowMargin(this.containerHeight)
            }
        },
        methods: {
            showAction(action) {
                if (! this.single && ! action.single_only) {
                    return true;
                }

                if (this.single) {
                    return true;
                }

                return false;
            },

            toggleShow() {
                this.show = !this.show;
            },

            close() {
                this.show = false;
            },

            async emitClick(action) {
                if (!action.active) {
                    this.$emit('action-blocked', action.id)
                    this.close();
                    return;
                }

                this.$emit('action-go', action.id)
                this.toggleShow();
            },
        },
        components: {
            VPopover,
        }
    }
</script>

<style lang="scss">
.bc-grey {
    background-color: rgba(251, 251, 251, var(--bg-opacity)) !important;
}

.child {
    opacity: 0;
    transition: opacity .2s;
    z-index: 1;
}

.parent:hover > .child{
    opacity: 1;
    z-index: 10;
}

// .select-block {
//     opacity: 0;
// }

.action-block:hover .select-block {
    display: block;
    z-index: 10;
}

.shipments-actions {
    // transform: translateX(calc(-100% + 15px));
}

.as-popover {
    z-index: 100;
    // margin-left: calc(-100%);
}

.as-trigger {
    position: absolute;
    left: 0;
    top: 10%;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: white;
}
</style>
